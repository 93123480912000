import request from '@/core/services/api.services.request'

export function getAllInvoice(params) {
  return request({
    url: '/invoice/list',
    method: 'get',
    params: params
  })
}

export function getInvoice(invoice_id) {
  return request({
    url: '/invoice/view/' + invoice_id,
    method: 'get',
  })
}

export function deleteInvoice(invoice_id) {
  return request({
    url: '/invoice/delete/' + invoice_id,
    method: 'delete',
  })
}

export function getPaymentMethods() {
  return request({
    url: '/invoice/payment-methods',
    method: 'get',
  })
}

export function getPayments(invoice_id) {
  return request({
    url: '/invoice/payments/'+invoice_id,
    method: 'get',
  })
}

export function downloadPdf(id,type) {
  return request({
    url: '/pdf/create/' + id +'/' + type,
    method: 'get',
    responseType: 'blob',
  })
}

export function getOutstandingInvoice(customer_id) {
  return request({
    url: 'customer/outstanding/invoice/list/'+customer_id,
    method: 'get',
   
  })
}

export function getBalance(customer_id) {
  return request({
    url: '/invoice/balance/'+customer_id,
    method: 'get',
  })
}

export function getInvoicePayment(customer_id) {
  return request({
    url: '/invoice/payment_list/'+customer_id,
    method: 'get',
  })
}

export function getCustomerDetails(customer_id) {
  return request({
    url: '/credit/customers/'+customer_id,
    method: 'get',
  })
}

export function getPaymentDetails(order_id) {
  return request({
    url: '/order/paymentdetails/'+order_id,
    method: 'get',
  })
}

export function getInvoiceRefund(order_id) {
  return request({
    url: '/invoice/refund/creditmemo/'+order_id,
    method: 'get',
  })
}