<!-- Settings -->
<template>
  <div class="card card-custom" v-loading="loading">
      <div class="card-body p-0">
          <!--begin: Settings-->
          <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
              <!--begin: Settings Body-->
              <div class="row justify-content-center my-10 px-8 px-lg-10">
                  <div class="col-xl-12 col-xxl-10 invoicepage">
                      <!--begin: Wizard Form-->
                      <form class="form" id="kt_form printSection">
                          <!--begin: Wizard Step 1-->
                          <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                            <el-form ref="form" :model="form" auto-complete="false">
                                <el-row v-if="view.website_details">
                                      <el-col :span="12" align="left" >
                                        <span v-loadimage="view.website_details.website_logo" ></span>                                           
                                      </el-col>
                                      <el-col :span="12" align="right">
                                           <b >
                                      <label class="mt-10">{{view.website_details.address}}, {{view.website_details.city_details.city_name}},{{view.website_details.state_details.state_name}} - {{view.website_details.post_code}}, {{view.website_details.country}}.</label><br>                                      
                                      <label>Phone: {{view.website_details.phone}}</label><br>
                                      <label>Fax: {{view.website_details.fax}}</label><br>
                                      <label>Email: {{view.website_details.email_address}}</label>
                                      </b>
                                      <br>
                                      </el-col>
                                </el-row>
                                <hr class="invoice-line">
                                <el-row>                                    
                                    <el-col :span="24" align="center">
                                    <h2 style="font-family: Arial;text-align: center;"><b>UPDATE INVOICE</b></h2>
                                    </el-col>                                                                            
                                </el-row>
                                <el-row style="margin-top:5px; margin-bottom:5px;">
                                     <el-col :span="12"  align="left">
                                         <span><b>ORDER : </b></span><span><b> {{view.order_number}}</b></span>
                                     </el-col>
                                      <el-col :span="12"  align="right">
                                         <span><b>CUSTOMER ID : </b></span><span v-if="view.customer"><b>{{view.customer.customer_id}}</b></span>
                                     </el-col>
                                </el-row>
                                  <el-row class="add-info">
                                    <el-col :span="8" >
                                        <div class="billing-adr" style="padding-left:10px;">BILL TO : </div>
                                        <div style="padding:3px" v-if="view.customer">{{view.customer?view.customer.first_name+' '+(view.customer.last_name ? view.customer.last_name : ''):''}}</div>
                                        <div>
                                          <el-row>
                                            <el-col
                                              :span="24"
                                              v-for="billing in billing_address"
                                              :key="billing.id"
                                              style="padding-left: 5px"
                                            >
                                            <div class="mt-1">
                                              <span v-if="billing.address_line_2">{{
                                                billing.address_line_2
                                              }}</span>
                                              <span v-if="billing.address_line_1"
                                                >, Unit No : {{ billing.address_line_1 }}</span
                                              >
                                            </div>
                                            <div class="mt-1">
                                              {{
                                                billing.city_details
                                                  ? billing.city_details.city_name
                                                  : ""
                                              }}
                                            </div >
                                            <div class="mt-1">
                                              {{
                                                billing.state_details
                                                  ? billing.state_details.state_name
                                                  : ""
                                              }}- {{ billing.post_code }}

                                              <span class="ml-5"> 
                                                <el-tag
                                                size="mini"
                                                v-on:click="bill = !bill"
                                                style="cursor: pointer"
                                                >Change</el-tag >
                                              </span>
                                            </div>
                                            </el-col>
                                      </el-row>
                                      
                                      <el-select
                                        v-if="bill"
                                        clearable
                                        filterable
                                        v-model="form.billing_address_id"
                                        placeholder="Select Address"
                                        style="width: 100%"
                                        :allow-create="true"
                                        @change="addBilling()"
                                        auto-complete="nope"
                                        class="mt-2"
                                      >
                                      <el-option class="add-container" value="">
                                      <a class="add-drop-btn" @click="createAddress(1)">
                                        <i class="el-icon-circle-plus"></i> Add Address
                                      </a>
                                      </el-option>

                                        <el-option
                                          v-for="address in billingAddresses"
                                          :key="address.id"
                                          :label="addressLabel(address)"
                                          :value="address.id"
                                        ></el-option>
                                      </el-select>
                                        </div>
                                    </el-col>
                                    <el-col :span="8" align="left" :offset="8" v-if="view.shipping_address_invoice">
                                       <div class="billing-adr" style="padding-left:10px;">SHIP TO :</div>
                                       <div style="padding:3px" v-if="view.customer">{{view.customer?view.customer.first_name+' '+(view.customer.last_name ? view.customer.last_name : ''):''}}</div>
                                        <div style="padding: 3px;" v-if="view.shipping_address_invoice.address_line_2"><span v-if="view.shipping_address_invoice.address_line_2">{{view.shipping_address_invoice.address_line_2}}</span>
                                          <span v-if="view.shipping_address_invoice.address_line_1">, Unit No : {{view.shipping_address_invoice.address_line_1}}</span></div>
                                        <div style="padding: 3px;" v-if="view.shipping_address_invoice.city_details && view.shipping_address_invoice.city_details.city_name">{{view.shipping_address_invoice.city_details.city_name}}</div>
                                        <div style="padding: 3px;" v-if="view.shipping_address_invoice.state_details && view.shipping_address_invoice.state_details.state_name">{{view.shipping_address_invoice.state_details.state_name+' - '+view.shipping_address_invoice.post_code}}</div>
                                    </el-col>
                                  </el-row>
                                  <el-row>
                                      <el-col :span="4">
                                      <el-form-item label="Customer P.O #" prop="customer_po">
                                        <el-input v-model="form.customer_po"></el-input>
                                      </el-form-item>
                                    </el-col>
                                    <el-col :span="6" :offset="1">
                                      <el-form-item
                                        label="Order Place By (Customer)"
                                        prop="sale_person"
                                      
                                      >
                                        <el-input
                                          v-model="form.order_by"
                                          @input.native="capitalize"
                                        ></el-input>
                                      </el-form-item>
                                      </el-col>
                                      <el-col :span="6"  :offset="1">
                                        <el-form-item label="Sales Person" prop="sale_person">
                                          <el-input
                                            v-model="form.sale_person"
                                            @input.native="capitalize"
                                          ></el-input>
                                        </el-form-item>
                                      </el-col>
                                      <el-col :span="5" :offset="1">
                                    <el-form-item label="Ship Via" prop="ship_via">
                                      <el-select
                                        v-model="form.ship_via"
                                        placeholder="Select"
                                        clearable
                                        :disabled="form.shiping_method == 'Store Pickup'"
                                      >
                                        <el-option class="add-container" value="">
                                          <a class="add-drop-btn" @click="CreateShipVia()">
                                            <i class="el-icon-circle-plus"></i> Add
                                          </a>
                                        </el-option>
                                        <el-option
                                          v-for="(shipvia, index) in shippingvia"
                                          :key="index"
                                          :label="shipvia.ship_type"
                                          :value="shipvia.ship_type"
                                          auto-complete="off"
                                        >
                                        </el-option>
                                      </el-select>
                                    </el-form-item>
                                    <span class="form-err" v-if="form.errors.has('ship_via')">{{
                                      form.errors.get("ship_via")
                                    }}</span>
                                  </el-col>
                                    </el-row>
                                    <div class="row">
                                    <div class="col-xl-12 ">
                                   
                                      <table search-options="" id="website-table" role="table" aria-busy="false" aria-colcount="14" class="table b-table tab-row-hover" style="margin-top:20px;">
                                          <tbody role="rowgroup">
                                               <tr class="billing-cl2 text-center" style="background-color:#366092; color: #ffffff">
                                                  <th aria-colindex="1" role="cell"><div >ITEM #</div></th>
                                                  <th aria-colindex="2" role="cell"><div >PRODUCT NAME</div></th>
                                                  <th aria-colindex="2" role="cell"><div >QTY</div></th> 
                                                  <th aria-colindex="2" role="cell"><div >Type</div></th>                                                   
                                                  <th aria-colindex="2" role="cell"><div >DISCOUNT</div></th>                                                   
                                                  <th aria-colindex="2" role="cell"><div >PRICE</div></th>
                                                  <th aria-colindex="2" role="cell"><div >AMOUNT</div></th>
                                              </tr>
                                              <tr class="billing-cl3" v-for="item in view.order_items" :key="item.id">
                                                  <td aria-colindex="1" role="cell" class="text-center"><div>{{item.product_item.sku}}</div></td>
                                                  <td aria-colindex="1" role="cell" ><div>{{item.product_item.name}}
                                                    <span v-if="item.product_item.main_supplier.mfg_id">- <b>{{item.product_item.main_supplier.mfg_id}}</b></span>
                                                    <span v-else-if="item.product_item.main_supplier.supplier_mfg_id">- <b>{{item.product_item.main_supplier.supplier_mfg_id}}</b></span>
                                                    <span v-else-if="item.product_item.main_supplier.upc_code">- <b>{{item.product_item.main_supplier.upc_code}}</b></span>
                                                    <span v-else>- <b>{{item.product_item.main_supplier.supplier_id}}</b></span>
                                                    </div></td>
                                                  <td aria-colindex="2" role="cell" class="text-center"><div>{{item.quantity}}</div></td>  
                                                  <td aria-colindex="2" role="cell" class="text-center"><div>{{item.type}}</div></td>                                                  
                                                   <td aria-colindex="1" role="cell" class="text-center"><div v-if="item.extra_discount">{{item.extra_discount}}%</div></td>                                                    
                                                  <td aria-colindex="2" role="cell" class="text-center" v-if="item.unit_price"><div>${{parseFloat(item.unit_price).toFixed(2)}}</div></td>
                                                  <td aria-colindex="1" role="cell" class="text-center"><div>${{parseFloat(item.total_price).toFixed(2)}}</div></td>
                                              </tr>
                                           <tr class="billing-cl3">
                                                <td aria-colindex="2" role="cell" colspan="5" >
                                                    <div class="padding-10"><b><u>Other Comments or Special Instructions</u></b></div>
                                                    <div class="padding-10">{{view.comments}}</div>
                                                    <div class="padding-10" style="margin-top:40px;"></div>
                                                    <div class="padding-10" style="margin-top:40px;" v-if="view.discount"><b>You save this order : ${{view.discount}}</b></div>
                                                    </td>
                                                <td aria-colindex="2" role="cell" >
                                                    <div class="padding-10">Sub Total</div>
                                                    <div class="padding-10" v-if="view.inside_price_total">Carry In Service</div>
                                                    <div class="padding-10" v-if="view.credit_amount">Credit Used</div>
                                                    <!-- <div class="padding-10" v-if="view.ct_discount">Customer Discount</div> -->
                                                    <div class="padding-10" v-if="view.discount">Discount</div>
                                                     <div class="padding-10" v-if="view.shipping">Shipping</div>
                                                     <div class="padding-10" v-else>Shipping</div>
                                                     <div class="padding-10" v-if="view.adjustment_price">Adjustment <span >{{view.adjustment_type == 1?'(+)' : '(-)'}}</span></div>
                                                    <div class="padding-10">HST({{view.tax_per}}%)</div>
                                                    <hr class="invoice-line">
                                                    <div class="padding-10">Total</div>
                                                    </td>
                                                <td aria-colindex="2" role="cell" class="text-center">
                                                    <div class="padding-10">${{parseFloat(view.sub_total).toFixed(2)}}</div>
                                                    <div class="padding-10" v-if="view.inside_price_total">${{parseFloat(view.inside_price_total).toFixed(2)}}</div>
                                                    <div class="padding-10" v-if="view.credit_amount">${{parseFloat(view.credit_amount).toFixed(2)}}</div>
                                                    <!-- <div class="padding-10" v-if="view.ct_discount">${{view.ct_discount}}</div> -->
                                                    <div class="padding-10" v-if="view.discount"><s>${{parseFloat(view.discount).toFixed(2)}}</s></div>
                                                    <div class="padding-10" v-if="view.shipping">${{parseFloat(view.shipping).toFixed(2)}}</div>                                                      
                                                    <div class="padding-10" v-else>
                                                      <span v-if="view.shipping_manual">${{parseFloat(view.shipping_manual).toFixed(2)}}</span>
                                                      <span v-else>--</span>
                                                    </div>
                                                    <div class="padding-10" v-if="view.adjustment_price">${{parseFloat(view.adjustment_price).toFixed(2)}}</div>
                                                     <div class="padding-10">
                                                        <span v-if="view.tax_total>0">$</span> 
                                                        <span v-if="view.tax_total>0" >{{parseFloat(view.tax_total).toFixed(2)}}</span>
                                                        <span v-else>--</span>
                                                     </div>
                                                    <hr class="invoice-line">
                                                    <div class="padding-10">${{parseFloat(view.total).toFixed(2)}}</div>
                                                    </td>  
                                              </tr>
                                          </tbody>
                                      </table>
                                     </div>
                                    </div>   
                                    <div v-if="payments.length > 0">
                                    
                                        <el-row class="mb-3 text-right" v-for="(pay,index) in payments" :key="index">
                                        <el-col >                                                                                 
                                          <span class="blc-label" v-if="pay.payment_method == 'Credit Card'">{{pay.brand}} **** **** **** {{pay.last_4}}</span>  
                                           <span class="blc-label" v-else>{{pay.payment_method}} <span v-if="pay.payment_method !='Record Payment'">Payment</span></span> 
                                          <span class="ml-2"> {{pay.payment_date | formatDate}}</span><span style="margin-left:5px;">Paid :</span><span > <b>$ {{pay.due_amount}}</b></span>
                                        </el-col>
                                      </el-row> 
                                      <el-row class="text-right mb-3">
                                        <el-col>
                                          <span class="blc-label">Balance amount to be Paid :</span><span><b>$ {{invoice.balance_amount}}</b></span>
                                        </el-col>
                                      </el-row> 
                                       <el-row class="mb-3 text-right" v-for="(refund,index) in refund_list" :key="index">
                                        <el-col >                                                                                 
                                          <hr style="color:black"/>
                                           <span class="blc-label">Refund Credit Memo :</span> 
                                          <span class="ml-2"><b>$ {{refund.amount}}</b></span>
                                        </el-col>
                                      </el-row> 
                                    </div>
                                   
                                 
                                  </el-form>
                                    <el-row style="text-align:center;">
                                      <el-button
                                        type="primary"
                                        @click="updateInvoice"
                                       class="mt-5"
                                        >Update</el-button>
                                      <el-col class="mt-10">
                                        <hr/>
                                      </el-col>
                                    </el-row>               
                                     <el-row>                                      
                                    <el-col :span="24" align="left">
                                    <div style="text-align: center;"><b>If you have any questions about this order, please contact</b></div>
                                    <div style="text-align: center; font-size:15px;"><b>EMAIL: INFO@CTSBUILDINGSUPPLIES.COM</b></div>
                                    </el-col>
                                     </el-row>                                     
                            
                             </div>
                          
                      </form>
                      <!--end: Settings Form-->
                  </div>
              </div>
              <!--end: Settings Body-->
          </div>
      </div>
      <!--end: Settings-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from 'moment';
import {getCorder, getShipVia,getCustomerAddress} from "@/api/order";
import {getPayments} from '@/api/invoice';
import Form from "@/core/services/api.form.services";
import CreateShipVia from "@/components/DropDown/shipVia";
import CreateAddress from "@/components/CreateAddress/addAddress";
import { globalEvents } from "@/utils/globalEvents";
export default {
name: 'view-order',
components: {},
 data() {
  return {
    loading: false,
    view:[],
    viewitem:[],
    view_type: 'invoice',
    payments: [],
    invoice: [],
    refund_list : [],
    shippingvia: [],
    billing_address:[],
    addresses:[],
    bill:false,
    form: new Form({
      customer_po:null,
      order_by:null,
      ship_via:null,
      sale_person:null,
      billing_address_id:null,
      customer_id:null
    })
  }
},
mounted() {
  this.$store.dispatch(SET_BREADCRUMB, [
    { title: "Invoices", route: "/invoice/list" },
    { title: "Update Invoice" }
  ]);
  globalEvents.$on("AddressAdded", (added) => {
      if (added) {
        this.addresses.push(added);
        if (added.type == "billing") {
          this.form.billing_address_id = added.id;
          this.billing_address.push(added);
          this.bill = false;
        } 
      }
    });
},
created() {
  this.fetchData(1)   
  //this.getPayments();
  this.getShipVia();
 
},
computed:{
  billingAddresses() {
      return this.addresses.filter((addr) => {
        return addr.type == "billing";
      });
    },
},
methods: {
  fetchData() {
    this.loading = true
    getCorder(this.$route.params.order_id).then(response => {
      this.view = response.data.data   
      this.form = new Form(response.data.data);
      this.invoice = response.data.data.invoice
      var invoice_id = response.data.data.invoice.id;   
      this.getPayments(invoice_id);
      this.getCustomerAddress();
      this.loading = false
    });
  },
  addBilling() {
      let billing_id = this.form.billing_address_id;
      this.billing_address = [];
      this.addresses.map((billing) => {
        if (billing.type == "billing") {
          if (billing_id == billing.id) {
            this.billing_address.push(billing);
            this.bill = false;
          }
        }
      });
    },
    getCustomerAddress(){
      getCustomerAddress(this.form.customer_id).then((response) => {
          this.loading = true
          //console.log(response.data.data,'/','dfhduasdh');
          this.addresses = response.data.data;
          this.billing_address = [];
          this.addresses.map((billing) => {
            if (billing.type == "billing") {              
              if(this.view.billing_address_id == billing.id){
                this.billing_address.push(billing);
              }
              this.bill = false;
            }

            this.loading = false;
          });
        });

        //console.log(this.addresses,'/',this.billing_address);
    },
  getShipVia() {
      this.loading = true;
      getShipVia().then((response) => {
        //console.log(response);
        this.shippingvia = response.data.data;
        this.loading = false;
      });
    },
  format_date(value){
      if (value) {
      return moment(String(value)).format('DD-MM-YYYY')
      }
  },
  getPayments(invoice_id){
    this.loading=true;
    getPayments(invoice_id).then(response => {
        this.payments = response.data.data
        this.loading = false;
    })
  },

  CreateShipVia() {
      this.$modal.show(
        CreateShipVia,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    updateInvoice(){
      this.api_url = "order/update/" + this.$route.params.order_id;
      this.form
        .post(this.api_url)
        .then((response) => {
          if (response.status) {
            this.$router.push({
              path: '/invoice/list',
            });
            this.loading = false;
            this.$showResponse("success", response.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error)
        });
    },
    addressLabel(address) {
      let formattedAddress = "";
      if (address.company_name) {
        formattedAddress += address.company_name + ", ";
      }
      if (address.address_line_1) {
        formattedAddress += address.address_line_1 + ", ";
      }
      if (address.address_line_2) {
        formattedAddress += address.address_line_2 + ", ";
      }
      if (address.city) {
        formattedAddress += address.city_details.city_name + ", ";
      }
      if (address.state) {
        formattedAddress += address.state_details.state_name + ", ";
      }
      if (address.post_code) {
        formattedAddress += address.post_code + ", ";
      }
      return formattedAddress;
    },
    createAddress(val) {
     
      
      var titleName = "Billing Address";
    
      this.$modal.show(
        CreateAddress,
        {
          customer_id: this.form.customer_id,
          address_type: val,
          label: {
            button: "Create",
            title: titleName,
          },
        },
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          width: "600px",
        }
      );
    },
}
};
</script>

<style >
  hr.invoice-line{
      border-top: 1px solid black;
      width: 100%;
  }
  .billing-adr, .shipping-adr{
       font-size: 15px; 
       background-color:#366092; 
       color: #ffffff;
       padding:3px;
      border:1px solid #366092;
  }
  .invoice-header{
      background-color:#366092; 
      color: #ffffff;
  }
  #website-table th, #website-table td {
    border: 1px solid #366092; padding: 5px;
  }
  .tab-row-hover {
      border: 1px solid #366092;
  }
  .add-info{
    margin-bottom: 20px;
  }
  .default-logo img{
    width: 125px;margin-top:30px;margin-left: 10px;
  }
  
  @media print {
     
      .print-section, .download-btn, .subheader, .action_btn, .action_dropdown {
          visibility: hidden;
          -webkit-print-color-adjust: exact;
      }
      .billing-adr, .shipping-adr{
       font-size: 15px; 
       background-color:#366092; 
       color: #ffffff;
       -webkit-print-color-adjust: exact;
      
  }

  .table th { 
      background-color: #366092 !important; 
       color: #ffffff !important;
       -webkit-print-color-adjust: exact;
  } 
  

}

</style>