<template>   
  <div >
      <div slot="header" class="modal-header popup-header">
            <span class="modal-title-popup"><i class="fa fa-user-circle" aria-hidden="true"></i>{{label.title}}</span>
            <i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
        </div>
        <div class="card card-custom" v-loading="loading">
          <div class="card-body  p-0">
              <!--begin: Orders-->
              <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                  <!--begin: Order Body-->
                  <div class="row justify-content-center">
                      <div class="col-xl-12">
                          <!--begin: Orders Form-->
                          <el-form ref="form" :model="form" auto-complete="false" style="padding:0px !important" :disabled="form.submiting">  
                            <el-row>                             
                              <el-col :span="24">
                                <el-form-item label="City Name" prop="city_name">
                                    <el-input v-model="form.city_name" @input.native="capitalize" auto-complete="nope"></el-input>
                                    <span class="form-err" v-if="form.errors.has('city_name')" >{{form.errors.get('city_name')}}</span>
                                </el-form-item>
                              </el-col>
                                <el-col :span="24">
                                  <div class="row">
                                    <div class="col-sm-12 ">
                                      <button class="btn btn-sm btn-primary font-weight-bold text-uppercase" @click.prevent="submitForm" :icon="form.submiting?'el-icon-loading':''">Create</button>                
                                    </div>
                                  </div>  
                                </el-col>
                            </el-row>
                          </el-form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import Form from '@/core/services/api.form.services'
import {globalEvents} from '@/utils/globalEvents';
export default {
  name: 'create-deliveryType',
   props: {
     stateId: {
	      	type: Number,
	      	required: true
	    },
  label:{
	      type: Object,
	      default() {
	        return {button:'Create',title:'Create City'}
	      }
	    }
  },
  data() {
    return {
      loading : false,
      form : new Form({
        state_id :null,  
        city_name : null
      })
    }
  },
  methods: {
    reset() {
      this.form.reset()
      this.$refs['form'].resetFields()
    },
    closeForm() {
      if (!this.form.submiting) {
        this.$emit('close')
      }
    },

    submitForm() {
      const formURL = 'create/city'
      //const msg = 'The term has been created'

      this.form.post(formURL).then((response) => {
        //this.$showResponse('success', msg);
        globalEvents.$emit('cityname', response.data)
        this.$emit('close')
        this.$showResponse('success', response.message);
      }).catch((error) => {
        console.log(error);
      })
    }
  },
   mounted() {    
        this.form.state_id = parseInt(this.stateId);
        
        }
   
}

</script>

<style scoped>
.option-info{
  float: left; color: #8492a6; font-size: 13px; clear:both;line-height: 20px;
}
.option-info i {
  color: #27304A;
}
.title{
  font-size: 16px;
  font-weight: 600;
  color: #27304A;
  display: inline-block;
  margin-bottom: 20px;
}
.event-container {
  background-color: white;
  padding: 20px;
  border: 1px solid #ebeef5;
}
.icon-pointer{
  font-size: 20px; cursor: pointer;color: #616e8e;
}
</style>
