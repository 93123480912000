<template>
  <div>
    <div slot="header" class="modal-header popup-header">
      <span class="modal-title-popup"
        ><i class="fa fa-address-card" aria-hidden="true"></i>
        {{ label.title }}</span
      >
      <i
        style="float: right; padding: 3px 0"
        class="model-close el-icon-close icon-pointer"
        @click.prevent="closeForm"
      ></i>
    </div>
    <el-form>
      <div
        class="card card-custom custom-model-popup page-scrll"
        v-loading="loading"
      >
        <div class="card-body p-0">
          <div class="row">
            <!-- begin: billing address -->
            <div class="col-xl-12">
              <div class="row">
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-sm-6">
                      <el-form-item label="Address" prop="address_line_2">
                        <vue-google-autocomplete
                          ref="origin1"
                          v-model="form.address_line_2"
                          id="address_map"
                          classname="form-control el-input__inner"
                          name="address_line_2"
                          placeholder="Enter your Address"
                          v-on:placechanged="getAddressData"
                          country="ca"
                        >
                        </vue-google-autocomplete>

                        <span
                          class="form-err"
                          v-if="form.errors.has('address_line_2')"
                        >
                          Address field is required.
                        </span>
                      </el-form-item>
                    </div>
                    <div class="col-sm-6">
                      <el-form-item label="Unit Number" prop="address_line_1">
                        <el-input
                          v-model="form.address_line_1"
                          @input.native="capitalize"
                          auto-complete="nope"
                          :class="
                            form.errors.has('address_line_1') ? 'error ' : ''
                          "
                        ></el-input>
                        <span
                          class="form-err"
                          v-if="form.errors.has('address_line_1')"
                        >
                          {{ form.errors.get("address_line_1") }}
                        </span>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <el-form-item label="Province" prop="state">
                        <el-select
                          ref="state"
                          filterable
                          clearable
                          v-model="form.state"
                          @change="getCityListByStateId()"
                          :class="form.errors.has('state') ? 'error ' : ''"
                        >
                          <el-option
                            v-for="(state, index) in state_list"
                            :key="index"
                            :label="state.state_name"
                            :value="state.state_id"
                          >
                          </el-option>
                        </el-select>
                        <span class="form-err" v-if="form.errors.has('state')">
                          Province is required.
                        </span>
                      </el-form-item>
                    </div>
                    <div class="col-sm-4">
                      <el-form-item label="City" prop="city">
                        <el-select
                          ref="city"
                          filterable
                          clearable
                          v-model="form.city"
                          :class="form.errors.has('city') ? 'error ' : ''"
                        >
                          <el-option class="add-container" value="">
                            <a class="add-drop-btn" @click="CreateCity()">
                              <i class="el-icon-circle-plus"></i>
                              Add City
                            </a>
                          </el-option>
                          <el-option
                            v-for="(city, index) in city_billing_list"
                            :key="index"
                            :label="city.city_name"
                            :value="city.city_id"
                          >
                          </el-option>
                        </el-select>
                        <span class="form-err" v-if="form.errors.has('city')">
                          {{ form.errors.get("city") }}
                        </span>
                      </el-form-item>
                    </div>
                    <div class="col-sm-4">
                      <el-form-item label="Postal Code" prop="post_code">
                        <el-input
                          v-model="form.post_code"
                          auto-complete="nope"
                          maxlength="7"
                          placeholder="M1S 3A7"
                          @change="validPostalshipping(form.post_code)"
                        ></el-input>
                      </el-form-item>
                      <span
                        class="form-err"
                        v-if="form.errors.has('post_code')"
                      >
                        {{ form.errors.get("post_code") }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end: billing address -->
          </div>
          <div class="row" style="margin-top: 15px">
            <div class="col-sm-12">
              <button
                class="btn btn-sm btn-primary font-weight-bold text-uppercase"
                @click.prevent="submitForm"
              >
                {{ label.button }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
import Form from "@/core/services/api.form.services";
import {
  getCityList,
  getStateList,
  getCityListByStateId,
  getStateByPrefix,
  getCityByName,
} from "@/api/common";
import { globalEvents } from "@/utils/globalEvents";
import CreateCity from "@/components/DropDown/addcity";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: "add_edit_customer",
  props: {
    address_type: {
      type: Number,
      required: true,
    },
    customer_id: {
      type: Number,
      required: true,
    },
    label: {
      type: Object,
      default() {
        return { button: "Create", title: "Add Address" };
      },
    },
  },
  data() {
    return {
      loading: false,
      addressType: null,
      error: null,
      form: new Form({
        customer_id: null,
        address_line_1: null,
        address_line_2: null,
        city: null,
        state: 8,
        post_code: null,
        type: "",
        latitude:null,
        longitude:null
      }),
      city_billing_list: [],
      city_shipping_list: [],
      state_list: [],
      submit_btn_text: "Create",
      title_text: "Add Address",
      api_url: "/customer/address/add-address",
      response_msg: "Address created successfully.",
    };
  },
  methods: {
    getCityListByStateId() {
      this.loading = true;
      getCityListByStateId(this.form.state).then((response) => {
        this.form.city = null;
        this.city_billing_list = response.data.data;
        this.loading = false;
      });
    },
    getAddressData: function (addressData) {
      if(addressData.postal_code != null){
        this.form.post_code = addressData.postal_code;
        this.form.latitude = addressData.latitude
        this.form.longitude = addressData.longitude
      }else{
        this.form.post_code = '';
      }
      
      var city_name = addressData.locality;
      if (city_name != null) {
        this.getCityIdByName(city_name);
      }
      var state_prefix = addressData.administrative_area_level_1;
      if (state_prefix != null) {
        this.getStateByStatePrefix(state_prefix);
      }
      var street = "";
      if (addressData.street_number != null) {
        street = addressData.street_number;
      }
      this.form.address_line_2 =
        street + " " + addressData.route;
      this.address = addressData;
    },
    getStateByStatePrefix(state_prefix) {
      getStateByPrefix(state_prefix).then((response) => {
        this.form.state = response.data.data.state_id;
      });
    },
    getCityIdByName(city_name) {
      getCityByName(city_name).then((response) => {
        const city_id = response.data.data.city_id;
        const stateId = response.data.data.state_id;
        getCityListByStateId(stateId).then((response) => {
          this.form.city = null;
          this.city_list = response.data.data;
          this.form.city = city_id;
          this.city_loading = false;
        });
      });
    },
    CreateCity() {
      this.$modal.show(
        CreateCity,
        { stateId: this.form.state },
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    getCityList() {
      this.loading = true;
      getCityList().then((response) => {
        this.city_billing_list = response.data.data;
        this.city_shipping_list = response.data.data;
        this.loading = false;
      });
    },
    getStateList() {
      this.loading = true;
      getStateList().then((response) => {
        this.state_list = response.data.data;
        this.loading = false;
      });
    },
    reset() {
      this.form.reset();
    },
    closeForm() {
      if (!this.form.submiting) {
        //globalEvents.$emit('AddressAdded', false)
        this.$emit("close");
      }
    },
    checkZip(value) {
      return /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/.test(value);
    },
    validPostalshipping(postalcode) {
      this.form.errors.add("post_code", "");
      let postal_code = postalcode.toUpperCase();
      if (this.checkZip(postal_code)) {
        this.form.post_code = postal_code
          .replace(/\W/g, "")
          .replace(/(...)/, "$1 ");
      } else {
        this.form.errors.add("post_code", "Invalid Postal Code");
        this.form.post_code = "";
      }
    },
    submitForm() {
      this.loading = true;
      this.form
        .post(this.api_url)
        .then((response) => {
          this.$message({
            message: this.response_msg,
            type: "success",
          });
          this.loading = false;
          globalEvents.$emit("AddressAdded", response.data);
          this.$emit("close");
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
  created() {
    this.getStateList();
    this.getCityList();
  },
  components: {
    VueGoogleAutocomplete,
  },
  mounted() {
    this.form.customer_id = this.customer_id;
    if (this.address_type == "1") {
      this.form.type = "billing";
    } else {
      this.form.type = "shipping";
    }

    globalEvents.$on("cityname", (type) => {
      if (type) {
        this.city_billing_list.push(type);
        this.form.city = type.city_id;
      }
    });
  },
};
</script>